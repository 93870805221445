import React from 'react';
import Image from "../assets/images/hero-img.png";

const Hero = () => (
  <section id="hero" className="d-flex align-items-center">
    <div className="container">
      <div className="row">
        <div className="col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0">
          <h1>Innovative Tech Solutions for Your Business</h1>
          <h2>We build state-of-the-art websites and mobile applications using the latest technologies.</h2>
        </div>
        <div className="col-lg-6 order-1 order-lg-2 hero-img">
          <img src={Image} className="img-fluid animated" alt="Hero" />
        </div>
      </div>
    </div>
  </section>
);

export default Hero;
