import { Col, Row } from 'antd';
import React from 'react';

const Services = () => (
  <section id="services" className="services section-bg">
    <div className="container">
      <div className="section-title">
        <h2>Services</h2>
        <p>We offer a range of services to help your business thrive in the digital age.</p>
      </div>
      <Row gutter={20}>
        <Col xl={6} lg={6} md={12} sm={24} xs={24} className='my-2' >
          <div className="icon-box">
            <div className="icon"><i className="bx bxl-dribbble"></i></div>
            <h4><a href="" className='text-decoration-none'>Web Development</a></h4>
            <p>Responsive and scalable websites tailored to your needs.</p>
          </div>
        </Col>
        <Col xl={6} lg={6} md={12} sm={24} xs={24} className='my-2' >
          <div className="icon-box">
            <div className="icon"><i className="bx bx-file"></i></div>
            <h4><a href="" className='text-decoration-none'>Mobile App Development</a></h4>
            <p>Intuitive and engaging mobile applications.</p>
          </div>
        </Col>
        <Col xl={6} lg={6} md={12} sm={24} xs={24} className='my-2' >
          <div className="icon-box">
            <div className="icon"><i className="bx bx-tachometer"></i></div>
            <h4><a href="" className='text-decoration-none'>Network Solutions</a></h4>
            <p>Comprehensive network solutions for optimal performance.</p>
          </div>
        </Col>
        <Col xl={6} lg={6} md={12} sm={24} xs={24} className='my-2' >
          <div className="icon-box">
            <div className="icon"><i className="bx bx-layer"></i></div>
            <h4><a href="" className='text-decoration-none'>IT Consultancy</a></h4>
            <p>Strategic IT consultancy services to achieve your business goals.</p>
          </div>
        </Col>
      </Row>
    </div>
  </section>
);

export default Services;
