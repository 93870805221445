import React from 'react';

const Footer = () => (
  <footer id="footer">

    <div class="footer-newsletter">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-6">
            <h4>Join Our Newsletter</h4>
            <p>Stay updated with the latest in technology and our offerings. Subscribe to our newsletter for insights and updates.</p>
            <form action="" method="post">
              <input type="email" name="email" /><input type="submit" value="Subscribe" />
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="footer-top">
      <div class="container">
        <div class="row">

          <div class="col-lg-3 col-md-6 footer-contact">
            <h3>Techie Sonic</h3>
            <p>
              Cairo <br />
              Egypt<br />
              <strong>Phone:</strong> +201004753538<br />
              <strong>Email:</strong> support@techiesonic.com<br />
            </p>
          </div>

          <div class="col-lg-3 col-md-6 footer-links">
            <h4>Useful Links</h4>
            <ul>
              <li><i class="bx bx-chevron-right"></i> <a className="text-decoration-none" href="#">Home</a></li>
              <li><i class="bx bx-chevron-right"></i> <a className="text-decoration-none" href="#">About us</a></li>
              <li><i class="bx bx-chevron-right"></i> <a className="text-decoration-none" href="#">Services</a></li>
              <li><i class="bx bx-chevron-right"></i> <a className="text-decoration-none" href="#">Terms of service</a></li>
              <li><i class="bx bx-chevron-right"></i> <a className="text-decoration-none" href="#">Privacy policy</a></li>
            </ul>
          </div>

          <div class="col-lg-3 col-md-6 footer-links">
            <h4>Our Services</h4>
            <ul>
              <li><i class="bx bx-chevron-right"></i> <a className="text-decoration-none" href="#">Web Design</a></li>
              <li><i class="bx bx-chevron-right"></i> <a className="text-decoration-none" href="#">Web Development</a></li>
              <li><i class="bx bx-chevron-right"></i> <a className="text-decoration-none" href="#">Product Management</a></li>
              <li><i class="bx bx-chevron-right"></i> <a className="text-decoration-none" href="#">Marketing</a></li>
              <li><i class="bx bx-chevron-right"></i> <a className="text-decoration-none" href="#">Graphic Design</a></li>
            </ul>
          </div>

          <div class="col-lg-3 col-md-6 footer-links">
            <h4>Our Social Networks</h4>
            <p>Follow us on social media for the latest updates and insights into our work and industry trends.</p>
            <div class="social-links mt-3">
              <a className="text-decoration-none" href="#" class="twitter"><i class="bx bxl-twitter"></i></a>
              <a className="text-decoration-none" href="#" class="facebook"><i class="bx bxl-facebook"></i></a>
              <a className="text-decoration-none" href="#" class="instagram"><i class="bx bxl-instagram"></i></a>
              <a className="text-decoration-none" href="#" class="google-plus"><i class="bx bxl-skype"></i></a>
              <a className="text-decoration-none" href="#" class="linkedin"><i class="bx bxl-linkedin"></i></a>
            </div>
          </div>

        </div>
      </div>
    </div>

    <div class="container footer-bottom clearfix">
      <div class="copyright">
        &copy; Copyright <strong><span>Techie Sonic</span></strong>. All Rights Reserved
      </div>
    </div>
  </footer>
);

export default Footer;
