import Navbar from './components/Navbar';
import Hero from './components/Hero';
import About from './components/About';
import Services from './components/Services';
import Contact from './components/Contact';
import Footer from './components/Footer';
import './assets/css/style.css';
import QueryProvider from './services/react-query';

function App() {
  return (
    <QueryProvider>
        <Navbar />
        <Hero />
        <About />
        <Services />
        <Contact />
        <Footer />
    </QueryProvider>
  );
}

export default App;
