// src/components/Navbar.js
import React, { useEffect } from 'react';
import { select, on, onscroll } from '../utils';
import 'bootstrap-icons/font/bootstrap-icons.css';
// import './Navbar.css';

const Navbar = () => {
  useEffect(() => {
    const navbarlinks = select('#navbar .scrollto', true);
    const navbarlinksActive = () => {
      let position = window.scrollY + 200;
      navbarlinks.forEach(navbarlink => {
        if (!navbarlink.hash) return;
        let section = select(navbarlink.hash);
        if (!section) return;
        if (position >= section.offsetTop && position <= (section.offsetTop + section.offsetHeight)) {
          navbarlink.classList.add('active');
        } else {
          navbarlink.classList.remove('active');
        }
      });
    };

    window.addEventListener('load', navbarlinksActive);
    onscroll(document, navbarlinksActive);

    const headerScrolled = () => {
      let selectHeader = select('#header');
      if (selectHeader) {
        if (window.scrollY > 100) {
          selectHeader.classList.add('header-scrolled');
        } else {
          selectHeader.classList.remove('header-scrolled');
        }
      }
    };

    window.addEventListener('load', headerScrolled);
    onscroll(document, headerScrolled);

    const toggleBacktotop = () => {
      let backtotop = select('.back-to-top');
      if (backtotop) {
        if (window.scrollY > 100) {
          backtotop.classList.add('active');
        } else {
          backtotop.classList.remove('active');
        }
      }
    };

    window.addEventListener('load', toggleBacktotop);
    onscroll(document, toggleBacktotop);

    on('click', '.mobile-nav-toggle', function(e) {
      let navbar = select('#navbar');
      if (navbar) {
        navbar.classList.toggle('navbar-mobile');
        this.classList.toggle('bi-list');
        this.classList.toggle('bi-x');
      }
    });

    on('click', '.navbar .dropdown > a', function(e) {
      let navbar = select('#navbar');
      if (navbar && navbar.classList.contains('navbar-mobile')) {
        e.preventDefault();
        this.nextElementSibling.classList.toggle('dropdown-active');
      }
    }, true);

    on('click', '.scrollto', function(e) {
      let hashElement = select(this.hash);
      if (hashElement) {
        e.preventDefault();
        let navbar = select('#navbar');
        if (navbar.classList.contains('navbar-mobile')) {
          navbar.classList.remove('navbar-mobile');
          let navbarToggle = select('.mobile-nav-toggle');
          navbarToggle.classList.toggle('bi-list');
          navbarToggle.classList.toggle('bi-x');
        }
        let elementPos = hashElement.offsetTop;
        window.scrollTo({
          top: elementPos - 80,
          behavior: 'smooth'
        });
      }
    }, true);

  }, []);

  return (
    <header id="header" className="fixed-top text-decoration-none">
      <div className="container d-flex align-items-center">
        <h1 className="logo me-auto text-decoration-none"><a href="/"className='text-decoration-none'> Techie Sonic</a></h1>
        <nav id="navbar" className="navbar">
          <ul>
            <li><a href="#hero" className="nav-link scrollto">Home</a></li>
            <li><a href="#about" className="nav-link scrollto">About</a></li>
            <li><a href="#services" className="nav-link scrollto">Services</a></li>
            <li><a href="#contact" className="nav-link scrollto">Contact</a></li>
            <li><a href="#about" className="text-decoration-none getstarted scrollto">Get Started</a></li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Navbar;
